<template>
    <div>
        <nl-loader v-if="preloading" :show="true" />
        <section v-else class="box">
            <b-tabs
                v-if="project"
                v-model="activeTab"
                @input="
                    value => {
                        openOnce[value] = true
                    }
                "
            >
                <b-tab-item
                    v-for="tab in tabs"
                    :disabled="tab.disabled"
                    :icon="tab.icon"
                    :key="tab.index"
                    :label="tab.label"
                    :value="tab.type"
                >
                    <TrackingOrderTable
                        v-if="openOnce[tab.index]"
                        :type="tab.type"
                        :status="tab.status"
                        @export="$refs.exportModal.openModal()"
                        @getDataUrl="getDataUrl"
                    />
                </b-tab-item>
            </b-tabs>
            <b-notification v-else type="is-warning is-light">
                {{ $t('suivi.error') }}
            </b-notification>
        </section>

        <TrackingOrderExportModal ref="exportModal" @exportOrders="exportOrders" />
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import TrackingOrderTable from '@/components/Dashboard/TrackingOrder/TrackingOrderTable'
    import TrackingOrderExportModal from '@/components/Dashboard/TrackingOrder/TrackingOrderExportModal.vue'

    export default {
        name: 'TrackingOrder',
        title: 'layout.dashboard',

        components: { TrackingOrderTable, TrackingOrderExportModal },

        data: function () {
            return {
                counts: null,

                statusToBeAccepted: 'a_valider',
                statusToBeTreated: 'en_cours',
                statusTerminate: 'termine',
                statusCanceled: 'annule',
                statusRefusal: 'refuse',
                statusInProgress: 'inProgress',

                //UI
                activeTab: 0,
                preloading: true,

                openOnce: {
                    0: true
                }
            }
        },
        watch: {
            project() {
                if (this.project?.id) {
                    if (this.counts && (!this.counts[this.activeTab] || this.counts[this.activeTab]?.total <= 0)) {
                        this.activeTab = 0
                    }
                    this.openOnce = {
                        [this.activeTab]: true
                    }
                    this.getData()
                }
            }
        },

        mounted() {
            this.getData()
            this.$echo.channel('channel-name').listen('.TestEvent', function (e) {
                console.log(e, 'aze')
            })
        },

        computed: {
            project() {
                return this.$store.state.project
            },
            tabs() {
                return [
                    {
                        type: 'all',
                        label:
                            this.$t('global.all') +
                            ` (${this.counts.reduce((accumulator, e) => accumulator + e.total, 0)})`,
                        icon: 'sitemap',
                        disabled: false,
                        index: 0
                    },
                    {
                        type: 'inProgress',
                        label: this.$t('status.running') + ` (${this.formattedCounts[this.statusInProgress] || 0})`,
                        icon: 'pen',
                        disabled:
                            !this.formattedCounts[this.statusInProgress] ||
                            this.formattedCounts[this.statusInProgress] <= 0,
                        index: 1,
                        status: this.statusInProgress
                    },
                    {
                        type: 'published',
                        label: this.$t('status.published-s') + ` (${this.formattedCounts[this.statusTerminate] || 0})`,
                        icon: 'clipboard-check',
                        disabled:
                            !this.formattedCounts[this.statusTerminate] ||
                            this.formattedCounts[this.statusTerminate] <= 0,
                        index: 2,
                        status: this.statusTerminate
                    },
                    {
                        type: 'canceled',
                        label: this.$t('status.canceled-s') + ` (${this.formattedCounts[this.statusCanceled] || 0})`,
                        icon: 'ban',
                        disabled:
                            !this.formattedCounts[this.statusCanceled] ||
                            this.formattedCounts[this.statusCanceled] <= 0,
                        index: 3,
                        status: this.statusCanceled
                    }
                ]
            },
            formattedCounts() {
                let obj = {}
                this.counts.forEach(i => {
                    obj[i.statut] = i.total
                })
                return obj
            }
        },

        methods: {
            ...mapActions('tracking_order', {
                getCounts: 'counts'
            }),
            getData() {
                this.getCounts(this.project.id)
                    .then(counts => {
                        this.counts = counts
                        this.preloading = false
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
            },
            exportOrders(params) {
                let link = null

                this.axios
                    .get(
                        `/tracking-order/${this.project.id}/export?format=${params.format}&from=${params.from}&to=${params.to}`
                    )
                    .then(response => {
                        link = response.data.download_link
                    })
                    .catch(error => this.snackNotif(error.response.data.message, 'is-danger'))
                    .finally(() => params.callback(link))
            },
            getDataUrl(params) {
                this.axios
                    .get(`/tracking-order/${params.order_id}/data`)
                    .then(response => {
                        params.callback(response.data.data)
                    })
                    .catch(error => {
                        this.snackNotif('Error', 'is-danger')
                        console.error(error)
                        params.callback([])
                    })
            },
            passOnDataChange(params, newProject) {
                this.orders.data = this.orders.data.map(order => {
                    if (params.ids.includes(order.id)) {
                        order.project_id = params.project_id
                        order.project = newProject

                        if (order.statut === this.statusTerminate) {
                            this.counts.published = this.counts.published - 1
                        }

                        if (order.statut === this.statusRefusal || order.statut === this.statusCanceled) {
                            this.counts.canceled = this.counts.canceled - 1
                        }

                        if (order.statut === this.statusToBeAccepted || order.statut === this.statusToBeTreated) {
                            this.counts.inProgress = this.counts.inProgress - 1
                        }
                    }

                    return order
                })
                this.orders.data = this.orders.data.filter(order => !params.ids.includes(order.id))
                this.orders.total = this.orders.total - params.ids.length

                if (this.ordersInProgress?.data) {
                    this.ordersInProgress.data = this.ordersInProgress.data.map(order => {
                        if (params.ids.includes(order.id)) {
                            order.project_id = params.project_id
                            order.project = newProject
                        }

                        return order
                    })
                    this.ordersInProgress.data = this.ordersInProgress.data.filter(
                        order => !params.ids.includes(order.id)
                    )
                }

                if (this.ordersPublished?.data) {
                    this.ordersPublished.data = this.ordersPublished.data.map(order => {
                        if (params.ids.includes(order.id)) {
                            order.project_id = params.project_id
                            order.project = newProject
                        }

                        return order
                    })
                    this.ordersPublished.data = this.ordersPublished.data.filter(
                        order => !params.ids.includes(order.id)
                    )
                }

                if (this.ordersCanceled?.data) {
                    this.ordersCanceled.data = this.ordersCanceled.data.map(order => {
                        if (params.ids.includes(order.id)) {
                            order.project_id = params.project_id
                            order.project = newProject
                        }

                        return order
                    })
                    this.ordersCanceled.data = this.ordersCanceled.data.filter(order => !params.ids.includes(order.id))
                }
            }
        }
    }
</script>
